<template>
    <a-drawer
        v-model:visible="visible"
        title="集成额外工时统计详情"
        @cancel="handleCancel"
        :destroyOnClose="true"
        :closable="false"
        @close="handleCancel"
    >
        <template #extra>
            <a-space direction="horizontal" :size="8">
                <a-button @click="handleCancel">取消</a-button>
                <a-button type="primary" @click="handleCancel">关闭</a-button>
            </a-space>
        </template>
        <a-descriptions :column="2">
            <a-descriptions-item label="报工流水号" :span="2">
                {{ detailRow?.code }}
            </a-descriptions-item>
            <a-descriptions-item label="工单号">
                {{ detailRow?.workOrderInfo?.code }}
            </a-descriptions-item>
            <a-descriptions-item label="项目名称">
                {{ detailRow?.workOrderInfo?.projectName }}
            </a-descriptions-item>
            <a-descriptions-item label="项目编码">
                {{ detailRow?.workOrderInfo?.projectCode }}
            </a-descriptions-item>
            <a-descriptions-item label="产品名称">
                {{ detailRow?.workOrderInfo?.productName }}
            </a-descriptions-item>
            <a-descriptions-item label="产品编码">
                {{ detailRow?.workOrderInfo?.productCode }}
            </a-descriptions-item>
            <a-descriptions-item label="集成编号">
                {{ detailRow?.workOrderInfo?.containerNo }}
            </a-descriptions-item>
            <a-descriptions-item label="提交人">
                {{ detailRow?.creatorDisplayName }}
            </a-descriptions-item>
            <a-descriptions-item label="提交时间">
                {{ detailRow?.createdTime }}
            </a-descriptions-item>
            <a-descriptions-item label="额外工时/h" :span="2">
                {{ detailRow?.otherWorkingHours }}
            </a-descriptions-item>
            <a-descriptions-item label="现场图片" :span="2">
                <a-image-preview-group>
                    <a-image
                        v-for="item in detailRow?.livePictureIds"
                        :key="item"
                        :src="`${baseURL}/web/mes-file-info/download/${item}`"
                        :width="100"
                        :height="100"
                    />
                </a-image-preview-group>
            </a-descriptions-item>
            <a-descriptions-item label="产生原因" :span="2">
                {{ detailRow?.cause }}
            </a-descriptions-item>
            <a-descriptions-item label="备注" :span="2">
                {{ detailRow?.remarks }}
            </a-descriptions-item>
        </a-descriptions>
    </a-drawer>
</template>
<script>
import { defineComponent, watch, reactive, toRefs, computed } from "vue";
import { apiJCWorkReportDetail } from "@/api";
import Select from "@/components/Select";
import { formateMoment } from "@/utils/common";
import { DownOutlined } from "@ant-design/icons-vue";
import Upload from "@/components/Upload";
import { useStore } from "vuex";
import { baseURL } from "@/utils/config";
export default defineComponent({
    props: ["detailRow", "status"],
    emits: ["eventUpdateOk", "update:detailRow"],
    components: {
        Select,
        DownOutlined,
        Upload,
    },
    setup(props, { emit }) {
        const { getters } = useStore();
        const state = reactive({
            visible: false,
            token: getters["user/token"],
        });

        const handleCancel = () => {
            state.visible = false;
            emit("update:detailRow", null);
        };

        watch(
            () => props.detailRow,
            newVal => {
                if (newVal !== null) {
                    state.visible = true;
                } else {
                    state.visible = false;
                }
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            formateMoment,
            baseURL,
        };
    },
});
</script>

<style lang="less" scoped>
.report-process-desc {
    color: grey;
    > div {
        margin-top: 16px;
        display: flex;
        > div {
            flex: 1;
        }
    }
}
.members-box {
    display: flex;
    flex-wrap: wrap;
}
</style>
