<template>
    <div class="container">
        <Detail v-model:detailRow="detailRow" />
        <FilterBlock @search="search" />
        <div class="table-content">
            <a-table
                :columns="columns"
                :data-source="data"
                bordered
                size="small"
                :loading="loading"
                :pagination="pagination"
                @change="paginationChange"
                :scroll="{ x: 2000 }"
                :destroyOnClose="true"
                :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                class="ant-table-striped"
            >
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'index'">
                        {{ index + 1 + (pagination.current - 1) * pagination.pageSize }}
                    </template>
                    <template v-if="column.dataIndex === 'projectName'">
                        {{ record?.workOrderInfo?.projectName }}
                    </template>
                    <template v-if="column.dataIndex === 'projectCode'">
                        {{ record?.workOrderInfo?.projectCode }}
                    </template>
                    <template v-if="column.dataIndex === 'productName'">
                        {{ record?.workOrderInfo?.productName }}
                    </template>
                    <template v-if="column.dataIndex === 'productCode'">
                        {{ record?.workOrderInfo?.productCode }}
                    </template>
                    <template v-if="column.dataIndex === 'containerNo'">
                        {{ record?.workOrderInfo?.containerNo }}
                    </template>
                    <template v-if="column.dataIndex === 'rework'">
                        {{ record?.rework ? "是" : "否" }}
                    </template>
                    <template v-if="column.dataIndex === 'mesProductTraceCode'">
                        {{ record?.workOrderInfo?.mesProductTraceCode }}
                    </template>
                    <template v-if="column.dataIndex === 'produceTeamName'">
                        {{ record?.workOrderInfo?.produceTeamName }}
                    </template>
                    <template v-if="column.dataIndex === 'statusDesc'">
                        <span :style="{ color: getStatusColor(record?.status) }">
                            {{ record?.statusDesc }}
                        </span>
                    </template>
                    <template v-if="column.dataIndex === 'workReportUsers'">
                        {{ record?.workReportUsers?.length }}
                    </template>
                    <template v-if="column.dataIndex === 'reportDate'">
                        {{ formateMoment(record?.productionEndTime, "YYYY-MM-DD") }}
                    </template>
                    <template v-if="column.dataIndex === 'reportTime'">
                        {{ formateMoment(record?.productionStartTime, "HH:mm") }} ~
                        {{ formateMoment(record?.productionEndTime, "HH:mm") }}
                    </template>
                    <template v-if="column.dataIndex === 'operate'">
                        <a-space direction="horizontal" :size="5">
                            <a @click="detailRow = record">详情</a>
                        </a-space>
                    </template>
                </template>
                <template #title>
                    <div class="operate-content">
                        <a-button></a-button>
                        <a-button type="primary" @click="handleExport">导出报表</a-button>
                    </div>
                </template>
            </a-table>
        </div>
    </div>
</template>

<script>
import { computed, defineComponent, reactive, toRefs } from "vue";
import FilterBlock from "./filterBlock";
import Detail from "./detail";
import Table from "@/components/Table";
import { formateMoment, jsonToUrlParams, getStatusColor, showMessage } from "@/utils/common";
import { apiExtraWorkHoursList } from "@/api";
import { baseURL } from "@/utils/config";
import { useStore } from "vuex";
const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 60,
        fixed: "left",
    },
    {
        title: "报工流水号",
        dataIndex: "code",
    },
    {
        title: "工单号",
        dataIndex: "workOrderCode",
    },
    {
        title: "项目名称",
        dataIndex: "projectName",
    },
    {
        title: "项目编码",
        dataIndex: "projectCode",
    },
    {
        title: "产品名称",
        dataIndex: "productName",
    },
    {
        title: "产品编码",
        dataIndex: "productCode",
    },
    {
        title: "集成编号",
        dataIndex: "containerNo",
    },
    {
        title: "提交人",
        dataIndex: "creatorDisplayName",
    },
    {
        title: "提交时间",
        dataIndex: "createdTime",
    },
    {
        title: "额外工时(/h)",
        dataIndex: "otherWorkingHours",
    },
    {
        title: "产生原因",
        dataIndex: "cause",
    },
    {
        title: "备注",
        dataIndex: "remarks",
    },
    {
        title: "操作",
        dataIndex: "operate",
        fixed: "right",
        width: 100,
    },
];
export default defineComponent({
    components: {
        FilterBlock,
        Detail,
        Table,
    },
    setup() {
        const { getters } = useStore();
        const state = reactive({
            detailRow: null,
            params: null,
            data: [],
            loading: false,
            pagination: {
                total: 0,
                current: 1,
                pageSize: 8,
                showSizeChanger: false,
            },
        });

        const handleExport = () => {
            let hasValue = false;
            if (state.params) {
                Object.values(state.params).find(item => {
                    if (typeof item == "string") item = item.trim();
                    if (item != undefined && item != "") {
                        hasValue = true;
                        return item;
                    }
                });
            }
            if (!state.params.createdTimeStart)
                return showMessage("info", "请至少选择创建时间范围");
            let url = `${baseURL}/web/mes-other-work-report/export-list`;
            url =
                url +
                jsonToUrlParams({
                    ...state.params,
                    "mes-token": getters["user/token"],
                });
            window.open(url);
        };

        const paginationChange = pagination => {
            state.pagination = pagination;
            getTabelData();
        };

        const getTabelData = async (params = state.params) => {
            state.loading = true;
            const { current: pageNum, pageSize } = state.pagination;
            let res = await apiExtraWorkHoursList({
                pageNum,
                pageSize,
                queryParam: {
                    ...params,
                },
            });
            state.loading = false;
            if (res.status === "SUCCESS") {
                state.pagination.total = res.data.totalRecord;
                state.data = res.data.recordList;
            }
        };

        const search = params => {
            state.params = params;
            state.pagination.current = 1;
            getTabelData(params);
        };

        return {
            columns,
            ...toRefs(state),
            search,
            paginationChange,
            getTabelData,
            formateMoment,
            baseURL,
            getStatusColor,
            handleExport,
        };
    },
});
</script>

<style lang="less" scoped>
:deep(.ant-switch-checked .ant-switch-handle) {
    left: calc(120% - 22px - 4px - 2px);
}
:deep(.ant-tabs-nav) {
    background: #fff;
    margin: 0;
    padding: 0 16px;
    box-sizing: border-box;
}
.container {
    .table-content {
        background-color: #fff;
        margin-top: 16px;
        padding: 16px;
        box-sizing: border-box;
        .operate-content {
            height: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}
.ant-table-striped :deep(.table-striped) td {
    background-color: #fafafa;
}
</style>
